import peticionHttp from "../peticionHttp";
const service = "api";


export const getEvaluationsService = async () => {
    const response = await peticionHttp.get(`${service}/evaluations`);
    return response;
};
export const addEvaluationService = async (payload) => {
    const response = await peticionHttp.post(`${service}/evaluations`, payload);
    return response;
};

export const getEvaluationService = async (id) => {
    const response = await peticionHttp.get(`${service}/evaluations/get/${id}`);
    return response;
};

export const editEvaluationService = async (payload) => {
    const response = await peticionHttp.put(`${service}/evaluations`, payload);
    return response;
};

export const deleteEvaluationService = async (id) => {
    const response = await peticionHttp.delete(`${service}/evaluations/${id}`);
    return response;
};
