import { ref, reactive, watch, inject } from "vue";
import {
    getEvaluationService,
    addEvaluationService,
    editEvaluationService,
    deleteEvaluationService,
} from "@/services/evaluations/evaluationsServices.js";
import useGetEvaluations from "./useGetEvaluations.js";
export default function useChallengesForm() {
    const title_modal = ref("");
    const method_post = ref(true);
    const clientId = ref(0);
    const isEdit = ref(false);

    const form = reactive({
        id: "",
        date: "",
        client_id: "",
        muscle_mass: "",
        weight: "",
        height: "",
        blood_pressure: "",
        fat_percentage: "",
        resting_pulse: "",
        sentadillas_peso_corporal_un_min: "",
        lagartijas_un_min: "",
        abdominales_un_min: "",
        max_fuerza_press_pecho: "",
        max_fuerza_sentadilla_detras: "",
        max_fuerza_front_squat: "",
        max_fuerza_press_hombro_delante: "",
        max_fuerza_peso_muerto_suelo: "",
        glucosa: "",
        vo2_max: "",
        cincokm: "",
        diezkm: "",
        veintiunokm: "",
        cuarenta_y_dos_ciento_noventa_y_cinco: "",
        isEdit:false
    });

    const add = (isEvaluation = false) => {
        cleanForm();
        if (isEvaluation) {
            $("#evaluationFormEdit").modal("toggle");
        } else {
            $("#evaluationForm").modal("toggle");
        }
        title_modal.value = "Evaluación";
        method_post.value = true;
        form.isEdit = !form.isEdit
    };

    const edit = async (id) => {
        cleanForm();
        title_modal.value = "Evaluación";
        method_post.value = false;
        const response = await getEvaluationService(id);
        if (response.status === 200) {
            Object.assign(form, response.data);
            form.isEdit = !form.isEdit
        } else {
            Swal.fire(
                "Error!",
                "No encontramos lo que buscaba!.",
                "error"
            ).then(() => {
                if (method_post.value) {
                    $("#evaluationForm").modal("toggle");
                } else {
                    $("#evaluationFormEdit").modal("toggle");
                }
            });
        }
    };

    const destroy = async ({ id, array }) => {
        const client_index = array.map((c) => c.id).indexOf(id);
        Swal.fire({
            title: "¿Estas seguro?",
            text: "Una vez eliminado, no podras revertir esto.",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Ok",
            cancelButtonText: "Cancelar",
        }).then(async (result) => {
            if (result.isConfirmed) {
                const response = await deleteEvaluationService(id);
                if (response.status === 200) {
                    Swal.fire("Hecho", response.message, "success").then(() => {
                        Array.isArray(array)
                            ? array.splice(client_index, 1)
                            : (window.location = route(service));
                    });
                } else {
                    Swal.fire("Error!", response.message, "error");
                }
            }
        });
    };

    const cleanForm = () => {
        Object.assign(form, {
            id: "",
            date: "",
            client_id: "",
            muscle_mass: "",
            weight: "",
            height: "",
            blood_pressure: "",
            fat_percentage: "",
            resting_pulse: "",
            sentadillas_peso_corporal_un_min: "",
            lagartijas_un_min: "",
            abdominales_un_min: "",
            max_fuerza_press_pecho: "",
            max_fuerza_sentadilla_detras: "",
            max_fuerza_front_squat: "",
            max_fuerza_press_hombro_delante: "",
            max_fuerza_peso_muerto_suelo: "",
            glucosa: "",
            vo2_max: "",
            cincokm: "",
            diezkm: "",
            veintiunokm: "",
            cuarenta_y_dos_ciento_noventa_y_cinco: "",
            
        });
        isEdit.value = false;
        /**
         * Resetea el formulario
         */
    };

    const { loading, getEvaluations, data } = useGetEvaluations();

    const submitForm = async () => {
        const response = method_post.value
            ? await addEvaluationService(form)
            : await editEvaluationService(form);
        if (response.status === 200) {
            Swal.fire("Hecho", response.message, "success").then(() => {
                cleanForm();
                if (method_post.value) {
                    getEvaluations();
                    $("#evaluationFormEdit").modal("toggle");
                } else {
                    getEvaluations();

                    $("#evaluationFormEdit").modal("toggle");
                }
            });
        } else {
            Swal.fire("Error!", response.message, "error").then(() => {
                if (method_post.value) {
                    $("#evaluationFormEdit").modal("toggle");
                } else {
                    $("#evaluationFormEdit").modal("toggle");
                }
            });
        }
    };

    return {
        title_modal,
        method_post,
        form,
        edit,
        destroy,
        add,
        clientId,
        submitForm,
        loading,
        getEvaluations,
        data,
        isEdit
    };
}
