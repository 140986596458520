<template>
  <teleport to="#modal-wrapper">
    <c-modal
      :id="[isEdit ? 'evaluationFormEdit' : 'evaluationForm']"
      :form="form"
      route_name="/api/evaluations/update"
      :title_modal="title_modal"
      :method_post="method_post"
      @onSubmit="submitForm()"
    >
      <template #modalBody>
        <div class="col-md-12">
          <div class="form-group">
            <label for="date">Fecha</label>
            <input
              type="date"
              name="date"
              v-model="form.date"
              class="form-control"
            />
          </div>
        </div>
        <div class="col-md-12">
          <div
            :id="[isEdit ? `accordion-edit` : 'accordion-add']"
            class="accordion accordion-primary"
          >
            <div class="accordion__item">
              <div
                class="accordion__header rounded-lg"
                data-toggle="collapse"
                :data-target="`#default_collapseOne-${isEdit ? 'edit' : 'add'}`"
              >
                <span class="accordion__header--text"
                  >Composicion Corporal</span
                >
                <span class="accordion__header--indicator"></span>
              </div>
              <div
                :id="`default_collapseOne-${isEdit ? 'edit' : 'add'}`"
                class="collapse accordion__body show"
                :data-parent="`#${isEdit ? `accordion-edit` : 'accordion-add'}`"
              >
                <div class="accordion__body--text">
                  <div class="row">
                    <div class="col-xl-4 col-md-6 col-sm-12">
                      <div class="form-group">
                        <label for="weight">Peso (Kilogramos)</label>
                        <input
                          type="number"
                          min="0"
                          name="weight"
                          v-model="form.weight"
                          class="form-control"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div class="col-xl-4 col-md-6 col-sm-12">
                      <div class="form-group">
                        <label for="height">Estatura (cm)</label>
                        <input
                          type="number"
                          name="height"
                          v-model="form.height"
                          min="0"
                          class="form-control"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div class="col-xl-4 col-md-6 col-sm-12">
                      <div class="form-group">
                        <label for="muscle_mass">% Masa muscular</label>
                        <input
                          type="number"
                          min="0"
                          name="muscle_mass"
                          v-model="form.muscle_mass"
                          class="form-control"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div class="col-xl-4 col-md-6 col-sm-12">
                      <div class="form-group">
                        <label for="fat_percentage">% grasa</label>
                        <input
                          type="number"
                          min="0"
                          name="fat_percentage"
                          v-model="form.fat_percentage"
                          class="form-control"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="accordion__item">
              <div
                class="accordion__header collapsed rounded-lg"
                data-toggle="collapse"
                :data-target="`#default_collapseTwo-${isEdit ? 'edit' : 'add'}`"
              >
                <span class="accordion__header--text"
                  >Resistencia muscular</span
                >
                <span class="accordion__header--indicator"></span>
              </div>
              <div
                :id="`default_collapseTwo-${isEdit ? 'edit' : 'add'}`"
                class="collapse accordion__body"
                :data-parent="`#${isEdit ? `accordion-edit` : 'accordion-add'}`"
              >
                <div class="accordion__body--text">
                  <div class="row">
                    <div class="col-xl-4 col-md-6 col-sm-12">
                      <div class="form-group">
                        <label for="sentadillas_peso_corporal_un_min"
                          >Sentadilla peso corporal en 1 minuto</label
                        >
                        <input
                          type="number"
                          min="0"
                          name="sentadillas_peso_corporal_un_min"
                          v-model="form.sentadillas_peso_corporal_un_min"
                          class="form-control"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div class="col-xl-4 col-md-6 col-sm-12">
                      <div class="form-group">
                        <label for="lagartijas_un_min"
                          >Lagartijas en 1 minuto</label
                        >
                        <input
                          type="number"
                          min="0"
                          name="lagartijas_un_min"
                          v-model="form.lagartijas_un_min"
                          class="form-control"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div class="col-xl-4 col-md-6 col-sm-12">
                      <div class="form-group">
                        <label for="abdominales_un_min"
                          >Abdominales en 1 minuto</label
                        >
                        <input
                          type="number"
                          min="0"
                          name="abdominales_un_min"
                          v-model="form.abdominales_un_min"
                          class="form-control"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="accordion__item">
              <div
                class="accordion__header collapsed rounded-lg"
                data-toggle="collapse"
                :data-target="`#default_collapsetres-${
                  isEdit ? 'edit' : 'add'
                }`"
              >
                <span class="accordion__header--text">Signos vitales</span>
                <span class="accordion__header--indicator"></span>
              </div>
              <div
                :id="`default_collapsetres-${isEdit ? 'edit' : 'add'}`"
                class="collapse accordion__body"
                :data-parent="`#${isEdit ? `accordion-edit` : 'accordion-add'}`"
              >
                <div class="accordion__body--text">
                  <div class="row">
                    <div class="col-xl-4 col-md-6 col-sm-12">
                      <div class="form-group">
                        <label for="blood_pressure">Presion arterial</label>
                        <input
                          type="text"
                          name="blood_pressure"
                          v-model="form.blood_pressure"
                          class="form-control"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div class="col-xl-4 col-md-6 col-sm-12">
                      <div class="form-group">
                        <label for="resting_pulse">Pulso en reposo</label>
                        <input
                          type="number"
                          name="resting_pulse"
                          v-model="form.resting_pulse"
                          min="0"
                          class="form-control"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div class="col-xl-4 col-md-6 col-sm-12">
                      <div class="form-group">
                        <label for="glucosa">glucosa</label>
                        <input
                          type="number"
                          min="0"
                          name="glucosa"
                          v-model="form.glucosa"
                          class="form-control"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="accordion__item">
              <div
                class="accordion__header collapsed rounded-lg"
                data-toggle="collapse"
                :data-target="`#default_collapsecuatro-${
                  isEdit ? 'edit' : 'add'
                }`"
              >
                <span class="accordion__header--text">Fuerza</span>
                <span class="accordion__header--indicator"></span>
              </div>
              <div
                :id="`default_collapsecuatro-${isEdit ? 'edit' : 'add'}`"
                class="collapse accordion__body"
                :data-parent="`#${isEdit ? `accordion-edit` : 'accordion-add'}`"
              >
                <div class="accordion__body--text">
                  <div class="row">
                    <div class="col-xl-4 col-md-6 col-sm-12">
                      <div class="form-group">
                        <label for="max_fuerza_press_pecho"
                          >Fuerza máxima / press pecho</label
                        >
                        <input
                          type="text"
                          name="max_fuerza_press_pecho"
                          v-model="form.max_fuerza_press_pecho"
                          class="form-control"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div class="col-xl-4 col-md-6 col-sm-12">
                      <div class="form-group">
                        <label for="max_fuerza_sentadilla_detras"
                          >Fuerza máxima / sentadilla detrás</label
                        >
                        <input
                          type="number"
                          name="max_fuerza_sentadilla_detras"
                          v-model="form.max_fuerza_sentadilla_detras"
                          min="0"
                          class="form-control"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div class="col-xl-4 col-md-6 col-sm-12">
                      <div class="form-group">
                        <label for="max_fuerza_front_squat"
                          >Fuerza máxima / front squat</label
                        >
                        <input
                          type="number"
                          min="0"
                          name="max_fuerza_front_squat"
                          v-model="form.max_fuerza_front_squat"
                          class="form-control"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div class="col-xl-4 col-md-6 col-sm-12">
                      <div class="form-group">
                        <label for="max_fuerza_press_hombro_delante"
                          >Fuerza máxima / press hombro por delante</label
                        >
                        <input
                          type="number"
                          min="0"
                          name="max_fuerza_press_hombro_delante"
                          v-model="form.max_fuerza_press_hombro_delante"
                          class="form-control"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div class="col-xl-4 col-md-6 col-sm-12">
                      <div class="form-group">
                        <label for="max_fuerza_peso_muerto_suelo"
                          >Fuerza máxima / peso muerto desde el suelo</label
                        >
                        <input
                          type="number"
                          min="0"
                          name="max_fuerza_peso_muerto_suelo"
                          v-model="form.max_fuerza_peso_muerto_suelo"
                          class="form-control"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="accordion__item">
              <div
                class="accordion__header collapsed rounded-lg"
                data-toggle="collapse"
                :data-target="`#default_collapsecinco-${
                  isEdit ? 'edit' : 'add'
                }`"
              >
                <span class="accordion__header--text"
                  >Resistencia Aeróbica</span
                >
                <span class="accordion__header--indicator"></span>
              </div>
              <div
                :id="`default_collapsecinco-${isEdit ? 'edit' : 'add'}`"
                class="collapse accordion__body"
                :data-parent="`#${isEdit ? `accordion-edit` : 'accordion-add'}`"
              >
                <div class="accordion__body--text">
                  <div class="row">
                    <div class="col-xl-4 col-md-6 col-sm-12">
                      <div class="form-group">
                        <label for="vo2_max">VO2max</label>
                        <input
                          type="text"
                          name="vo2_max"
                          v-model="form.vo2_max"
                          class="form-control"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div class="col-xl-4 col-md-6 col-sm-12">
                      <div class="form-group">
                        <label for="cincokm">5 km</label>
                        <input
                          type="number"
                          name="cincokm"
                          v-model="form.cincokm"
                          min="0"
                          class="form-control"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div class="col-xl-4 col-md-6 col-sm-12">
                      <div class="form-group">
                        <label for="diezkm">10 km</label>
                        <input
                          type="diezkm"
                          min="0"
                          name="diezkm"
                          v-model="form.diezkm"
                          class="form-control"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div class="col-xl-4 col-md-6 col-sm-12">
                      <div class="form-group">
                        <label for="veintiunokm">21 km</label>
                        <input
                          type="number"
                          min="0"
                          name="veintiunokm"
                          v-model="form.veintiunokm"
                          class="form-control"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div class="col-xl-4 col-md-6 col-sm-12">
                      <div class="form-group">
                        <label for="cuarenta_y_dos_ciento_noventa_y_cinco">42kk / 195</label>
                        <input
                          type="number"
                          min="0"
                          name="cuarenta_y_dos_ciento_noventa_y_cinco"
                          v-model="form.cuarenta_y_dos_ciento_noventa_y_cinco"
                          class="form-control"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </c-modal>
  </teleport>
</template>

<script>
import CModal from "@/components/modals/CModal.vue";
export default {
  components: {
    CModal,
  },
  props: {
    form: Object,
    method_post: Boolean,
    title_modal: String,
    clientId: Number,
    isEdit: Boolean,
    submitForm: Function,
  },
  setup(props) {
    return {
    };
  },
};
</script>

<style>
.error input {
  border-color: #f79483;
}
.mt-c {
  margin-top: 1.8rem;
}

.btn-random {
  margin-top: 2.4rem;
}
</style>
