<template>
  <bread-layout
    v-if="isOpcionSidebar"
    :items="breadcrumbLinks"
    @add="add(true)"
    add_button
  ></bread-layout>
  <div class="col-lg-12">
    <div class="card">
      <div class="card-header border-bottom-4">
        <h4 class="card-title">Tabla de Evaluaciones</h4>
      </div>
      <div class="card-body">
        <div class="table-responsive">
          <div class="table-wrapper-scroll-y my-custom-scrollbar">
            <table class="table">
              <thead>
                <tr>
                  <th>Fecha</th>
                  <th>Peso (kg)</th>
                  <th>Altura (cm)</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(evaluation, index) in evaluations" :key="index">
                  <td>{{ dateFormat(evaluation.date) }}</td>
                  <td>{{ evaluation.weight }}</td>
                  <td>{{ evaluation.height }}</td>
                  <td>
                    <div class="row">
                      <div class="col-md-6">
                        <a
                          href="#"
                          class="btn btn-primary"
                          data-target="#evaluationFormEdit"
                          data-toggle="modal"
                          data-placement="top"
                          title="Editar evaluación"
                          @click.prevent="edit(evaluation.id)"
                        >
                          <i class="fa fa-eye"></i>
                        </a>
                      </div>
                      <div class="col-md-6">
                        <a
                          href="#"
                          class="btn btn-danger"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Eliminar"
                          @click.prevent="
                            destroy({ id: evaluation.id, array: evaluations })
                          "
                        >
                          <i class="fa fa-trash"></i>
                        </a>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <EvaluationForm
    :form="form"
    :title_modal="title_modal"
    :method_post="method_post"
    :isEdit="true"
    :clientId="clientId"
    :submitForm="submitForm"
  />
</template>

<script>
import { ref } from "vue";
import BreadLayout from "@/layouts/BreadLayout.vue";
import useFormat from "@/composables/format/useFormat.js";
import useEvaluationForm from "@/composables/evaluations/useEvaluationsForm.js";
import EvaluationForm from "./EvaluationForm.vue";
export default {
  components: {
    EvaluationForm,
    BreadLayout,
  },
  props: {
    isOpcionSidebar: Boolean,
    evaluations: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const breadcrumbLinks = ref([
      {
        text: "Evaluaciones",
        path: "/evaluaciones",
      },
    ]);

    const { dateFormat } = useFormat();
    const {
      title_modal,
      method_post,
      form,
      edit,
      clientId,
      destroy,
      submitForm,
      loading,
      getEvaluations,
      data: evaluations,
      add,
    } = useEvaluationForm();
    getEvaluations();
    return {
      evaluations,
      dateFormat,
      title_modal,
      method_post,
      form,
      edit,
      clientId,
      getId: (id) => {
        edit(id);
      },
      destroy,
      submitForm,
      loading,
      breadcrumbLinks,
      add,
    };
  },
};
</script>

<style scoped>
.my-custom-scrollbar {
  position: relative;
  height: 650px;
  overflow: auto;
}
.table-wrapper-scroll-y {
  display: block;
}
</style>