<template>
    <div
        class="modal fade bd-example-modal-lg"
        :id="id"
        tabindex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
    >
        <div :class="`modal-dialog modal-${size}`">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title h4" id="myLargeModalLabel">{{ title_modal }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <form
                    method="POST"
                    :action="route_name"
                    enctype="multipart/form-data"
                    @submit.prevent="$emit('onSubmit')"
                    :novalidate="customValidate"
                >
                    <slot name="hidden"></slot>
                    <div class="modal-body">
                        <div class="row">
                            <slot name="modalBody"></slot>
                        </div>
                    </div>
                    <div class="modal-footer" v-if="modal_footer">
                        <div v-if="!method_post">
                            <input type="hidden" name="_method" value="PUT" />
                            <input type="hidden" name="id" :value="form ? form.id : ''" />
                        </div>
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                        <button
                            v-if="!readOnly"
                            type="submit"
                            class="btn btn-primary d-flex justify-content-around align-items-center"
                            :class="{ 'not-allowed': btnSubmit || btnSave }"
                            :disabled="btnSubmit || btnSave"
                        >
                            <c-spinner :loading="btnSubmitLoading" :width="16" :height="16"></c-spinner>
                            <span class="ml-1">{{ submitText }}</span>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import CSpinner from '../spinners/CSpinner.vue'
export default {
  components: { CSpinner },
    props: {
        id: String,
        route_name: String,
        form: Object,
        title_modal: String,
        method_post: {
            type: Boolean,
            default: true
        },
        size: {
            type: String,
            default: 'lg'
        },
        btnSubmit: {
            type: Boolean,
            default: false
        },
        btnSubmitLoading: {
            type: Boolean,
            default: false
        },
        btnSave: {
            type: Boolean,
            default: false
        },
        modal_footer: {
            type: Boolean,
            default: true
        },
        readOnly: {
            type: Boolean,
            default: false
        },
        submitText: {
            type: String,
            default: 'Guardar'
        },
        customValidate: {
            type: Boolean,
            default: true
        }
    },
    emits: ['onSubmit'],
}
</script>

<style scoped>
.not-allowed {
    cursor: not-allowed;
}
</style>
